<template lang="pug">
  include ../../../configs/template
  div.pageList
    div.w-100
      +select-validation('dataForm.login', 'mappingLogin', 'login', '"username"', '["required"]')(return-object clearable)
    div.w-100.d-flex.justify-center.align-center
      v-btn(
          @click="registrationUbikey()"
          :loading="buttonLoader"
          color="success"
      ) {{ $t('addKey') }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ubikey } from '@/mixins/validationRules'

function formFieldsInitialState () {
  return {
    login: null
  }
}

export default {
  name: 'AddUbikeyToLogin',
  props: {
    fingerRegistration: Function
  },
  data () {
    return {
      u2f: window.u2f,
      dataForm: formFieldsInitialState(),
      buttonLoader: false
    }
  },
  mounted () {
    this.getAllUsers()
  },
  computed: {
    ...mapState({
      mappingLogin: state => state.user.userList.results
    })
  },
  validations () { return ubikey() },
  methods: {
    ...mapActions(['getAllUsers', 'registrationU2F', 'createRegister']),

    registrationUbikey () {
      if (this.$v.$invalid) return this.$v.$touch()
      const id = this.dataForm.login.id // user id
      const response = this.registrationU2F({ id })
      if (response.code === 200) {
        this.$swal(this.$i18n.t('putFingerOnKey'))
        this.register(response.data.register_request, id)
      } else this.$notification.error('notify.title.error')
    },

    register (reg, id) {
      const regReq = {
        challenge: reg.registerRequests[0].challenge,
        version: reg.registerRequests[0].version
      }
      this.u2f.register(reg.appId, [regReq], [], (keyAuthResponse) => {
        if (!keyAuthResponse.errorCode) {
          keyAuthResponse.user_id = id
          const response = this.createRegister({ id, body: keyAuthResponse })
          if (response.data.status === 'created') {
            this.$swal(this.$t('userWasCreated'))
            this.dataForm = formFieldsInitialState()
            this.$v.$reset()
          } else if (response.data.status === 'device exist') this.$notification.error('userExist')
        } else this.$notification.error('errorRegKey')
      })
    }
  }
}
</script>
